.header {
    position: fixed;
    height: 100px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    transition: .3s ease-in;
    overflow: hidden;
    background-color: #f6f6f6;
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}

.header .nav-menu a {
    color: #000;
}



.header .navbar img {
    width: 90px;
    height: 90px;
}

.title {
    font-size: 22px;
    font-weight: 500;
    margin-top: 15px;
    padding-right: 84px;
    font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

}

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 500;
    color: #000;
    cursor: pointer;
}
.header .nav-item .active{
    color: red;
}

.hamburger {
    display: none;
}



@media screen and (max-width:940px) {
    .header {
        max-width: 100%;
        background-color: #f6f6f6;
    }

    .header .navbar {
        max-width: 100%;
    }

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
        background-color: rgba(0, 0, 0, .9);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }

    .nav-menu.active {
        left: 0;
        background-color: #f6f6f6;
    }

    .title {
        display: none;
    }

    .nav-item {
        margin: 0;
    }

    .header .navbar img {
        width: 90px;
    }
}